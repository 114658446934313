<template>
	<div class="orderDetailpage" v-loading="detailoading">
		<div class="orderInformation">
			<div class="mainCon">
				<div class="line flexRow">
					<div class="flexRow" style="margin-right:20px">
						
						<el-tag type="danger" effect="dark" size="mini" style="background:#FF7A45FF;line-height:20px;height:20px;margin-right:10px"
						 v-if="orderDetail.IsPushedErp">已推送至ERP</el-tag>
						<el-popover placement="top-start" trigger="hover" :content="orderDetail.RoutuanRemark" style="margin-right:10px"
						 v-if="orderDetail.RoutuanDealState==2">
							<el-tag type="danger" effect="dark" size="mini" slot="reference"
								style="background:#FF7A45FF;line-height:20px;height:20px;margin-right:0px">推送异常</el-tag>
						</el-popover>
						<el-tag type="danger" effect="dark" size="mini" slot="reference" v-if="orderDetail.RoutuanDealState==1"
							style="background:#FF7A45FF;line-height:20px;height:20px;margin-right:10px">已推送至肉团</el-tag>

						<span style="margin:0 10px">
							<span style="font-weight:bold">订单编号：</span>
							<span style="color:#F56C6C;" v-if="orderDetail.SendType==0">[快递发货]</span>
							<span style="color:#F56C6C;" v-else-if="orderDetail.SendType==1">[到店自提]</span>
							<span style="color:#F56C6C;" v-else-if="orderDetail.SendType==2">[同城配送]</span>
							{{orderDetail.OrderNo}}
						</span>
						<el-tooltip :content="'微信交易单号：'+orderDetail.PayNo" placement="bottom" effect="light"
							v-if="orderDetail.PayNo">
							<span style="color:#409EFF;cursor: pointer;">更多</span>
						</el-tooltip>
					</div>
					<div class="flexRow" style="margin-right:20px" >
						<span style="font-weight:bold">订单状态：</span>
						{{orderDetail.OrderStateDisplay}}
					</div>
					<div class="flexRow" style="margin-right:20px" v-if="orderDetail.SenderTypeValue" >
						<span style="font-weight:bold">发货方：</span>
						{{orderDetail.SenderTypeValue}}
					</div>
					<div class="flexRow" style="margin-right:20px" >

						<buttonPermissions :datas="'addStartOrderBtn'">
							<el-popover placement="top-start" trigger="click" style="margin: 0 5px;min-width:160px;">
								<div style="display: flex;align-items: center;align-items: center;">
									<el-button type="text" style="margin-right:5px;"
										@click="orderDetail.Star=0,rateChange()" v-if='orderDetail.Star'>清空</el-button>
									<el-rate v-model="orderDetail.Star" @change="rateChange"></el-rate>
								</div>

								<el-button slot="reference" type="text" class="pointers" @click="addStar"
									style="padding: 0">
									<div v-show='!orderDetail.Star'>加星</div>
									<div v-show='orderDetail.Star'
										style="display: flex;align-items: center;align-items: center;">
										<span>{{orderDetail.Star}}</span>
										<el-rate style="display: inline-block;vertical-align: middle;"
											v-model="startCount" :max="1"></el-rate>
									</div>
								</el-button>
							</el-popover>
						</buttonPermissions>

					</div>
				</div>
				<!-- CustomerRemark -->
				<el-alert style="word-wrap:break-word;width:95%;" 
					type="warning" :closable="false" v-if="orderDetail.CustomerRemark">
					<div style="display:flex" slot="title">
						买家留言：
						<div style="display:flex;flex-direction:column">
							<div v-for="(v,i) in CustomerRemark" :key="i">{{v}}</div>
						</div>
					</div>
				</el-alert>
				<!-- 待付款 -->
				<!-- <el-alert style="word-wrap:break-word;width:95%;" :title="'系统备注:'+ orderDetail.SystemRemark"
					type="success" :closable="false" v-if="orderDetail.SystemRemark"></el-alert> -->
				<div class="system-remark" v-if="orderDetail.SystemRemark||(orderDetail.SystemImgRemarkComplete&&orderDetail.SystemImgRemarkComplete.length)">系统备注:{{orderDetail.SystemRemark}}
					<el-image v-for="v in orderDetail.SystemImgRemarkComplete" :key="v" class="el-image-whiteclose"
						style="width: 36px; height: 36px;margin-left:5px"
						:src="v" 
						:preview-src-list="orderDetail.SystemImgRemarkComplete">
					</el-image>
				</div>

				<el-button style="margin:20px 0" v-if="[null,2].includes(orderDetail.RoutuanDealState)" @click="ordermarkDialogVisible=true">备注</el-button>

			</div>
			<div class="mainCon" v-if="orderDetail.ExpressList&&orderDetail.ExpressList.length">
				<el-tabs v-model="activeTabName" type="card" @tab-click="handleChangeTab">
					<el-tab-pane :key="index" v-for="(item, index) in orderDetail.ExpressList" :label="'包裹'+(index+1)"
						:name="item.name">
						<div class="pack-tabs">
							<div class="left-content">
								<div>
									物流公司：{{item.ExpressValue}}
									<buttonPermissions :datas="'modifyOrderSend'" v-if='orderDetail.OrderState!=5'>
										<el-button type="text" style="margin-left: 5px;"
											@click='handleModifySend(item.ExpressValue,item.ExpressNo,item.DetailIdList)'>
											修改物流</el-button>
									</buttonPermissions>
								</div>
								<div>物流单号：{{item.ExpressNo}}</div>
								<div>发货时间：{{item.SendTime}}</div>
								<div class="imgs-content" :style="{width: (widthUp1366 ? '725px' : '420px')}">
									<i class="el-icon-arrow-left icon-left" @click="handleLeft"
										v-show="bages.current>1"></i>
									<i class="el-icon-arrow-left icon-unleft" v-show="bages.current==1"></i>

									<div class="imgs-list" :style="{width: (widthUp1366 ? '675px' : '375px')}">
										<ul :style="{transform:`translateX(-${(bages.current-1)*(bages.size)*75}px)`}">
											<li class="imgs-item" v-for="(tt,index) in item.ProductList" :key="index">
												<img :src="tt.ImgUrlComplete+'@!cut64'">
												<div>{{tt.ProductName}}</div>
											</li>
										</ul>
									</div>

									<i class="el-icon-arrow-right icon-right" @click="handleRight"
										v-show="bages.rest>0"></i>
									<i class="el-icon-arrow-right icon-unright" v-show="bages.rest<=0"></i>
								</div>
							</div>
							<div class="right-content">
								<p>
									<span style="color:#F56C56">*</span>
									<span>物流跟踪：</span>
								</p>
								<el-timeline style="height:180px;overflow:auto" v-if="item.ExpressTraceList.length">
									<el-timeline-item v-for="(j,index) in item.ExpressTraceList" :key="index"
										:timestamp="j.AcceptTime" placement="top">
										<el-card style="padding:0 5px">
											<p style="margin-top:5px;word-wrap:break-word;">{{j.AcceptStation}}</p>
										</el-card>
									</el-timeline-item>
								</el-timeline>

								<div v-else style="text-align: center;line-height:180px;">暂未跟踪到物流信息</div>

							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<!-- 同城配送 -->
			<div class="mainCon" v-if="orderDetail.SendType==2">
				<el-tabs v-model="activeTabName" type="card">
					<el-tab-pane label="包裹1" name="tab1">
						<div class="pack-tabs">
							<div class="left-content" style="">
								<!-- 这里是用来自测的 -->
								<!-- <el-button type="text" style="color:#f56c6c;margin-right:10px" @click="cancalclllShow=true">取消呼叫</el-button>
												<el-button type="text" @click="toAddtip">加小费</el-button>
												<el-button type="text" @click="callagain">重新呼叫</el-button> -->
								<div>配送方：{{orderDetail.DeliveryInfo.InCityDeliveryCompanyTypeDisplay}}</div>
								<div v-if="orderDetail.DeliveryInfo.InCityDeliveryCompanyType==0">
									<div>配送门店：{{orderDetail.DeliveryInfo.ShopName}}</div>
								</div>
								<!--  -->
								<div v-else>
									<!-- <div>配送方：{{orderDetail.DeliveryInfo.InCityDeliveryCompanyTypeDisplay}}</div> -->
									<div>配送单号：{{orderDetail.DeliveryInfo.InCityDeliveryThirdPartyNo}}</div>
									<div class="flexRow">配送员：
										<div>
											<span style="margin-right:20px" v-if="[1,6].includes(orderDetail.DeliveryInfo.InCityDeliveryState)">{{orderDetail.DeliveryInfo.InCityDeliveryStateDisplay}}</span>
											<span style="margin-right:20px" v-else>{{orderDetail.DeliveryInfo.InCityDeliveryRiderName}}({{orderDetail.DeliveryInfo.InCityDeliveryRiderPhone}})</span>
											<buttonPermissions :datas="'orderdetailcancalcall'" v-if="[1,2].includes(orderDetail.DeliveryInfo.InCityDeliveryState)">
												<el-button type="text" style="color:#f56c6c;margin-right:10px" @click="cancalclllShow=true">取消呼叫</el-button>
											</buttonPermissions>
											<buttonPermissions :datas="'orderdetailpushtip'" v-if="[1,2,3,4].includes(orderDetail.DeliveryInfo.InCityDeliveryState)">
												<el-button type="text" @click="toAddtip">加小费</el-button>
											</buttonPermissions>
											<buttonPermissions :datas="'orderdetailcallagain'" v-if="[6].includes(orderDetail.DeliveryInfo.InCityDeliveryState)&&orderDetail.OrderState==3">
												<el-button type="text" @click="callagain">重新呼叫</el-button>
											</buttonPermissions>
										</div>
										<!-- <div v-else>
											{{orderDetail.DeliveryInfo.InCityDeliveryRiderName}}({{orderDetail.DeliveryInfo.InCityDeliveryRiderPhone}})
										</div> -->
									</div>
									<div>取件码：{{orderDetail.DeliveryInfo.InCityDeliveryPickUpCode}}</div>
									<div>配送费用：￥{{orderDetail.DeliveryInfo.InCityDeliveryTotalPrice}}</div>
								</div>
								<div class="imgs-content" :style="{width: (widthUp1366 ? '725px' : '420px')}">
									<i class="el-icon-arrow-left icon-left" @click="handleLeft" v-show="bages.current>1"></i>
									<i class="el-icon-arrow-left icon-unleft" v-show="bages.current==1"></i>

									<div class="imgs-list" :style="{width: (widthUp1366 ? '675px' : '375px')}">
										<ul :style="{transform:`translateX(-${(bages.current-1)*(bages.size)*75}px)`}">
											<li class="imgs-item" v-for="(tt,index) in orderDetail.DeliveryInfo.ProductList" :key="index">
												<img :src="tt.ImgUrlComplete+'@!cut64'">
												<div>{{tt.ProductName}}</div>
											</li>
										</ul>
									</div>

									<i class="el-icon-arrow-right icon-right" @click="handleRight"
										v-show="bages.rest>0"></i>
									<i class="el-icon-arrow-right icon-unright" v-show="bages.rest<=0"></i>
								</div>
							</div>
							<div class="right-content">
								<div class="flexRow">
									<span style="color:#F56C56">*</span>
									<span>物流跟踪：</span>
									<div class="flexRow" v-if="orderDetail.DeliveryInfo.InCityDeliveryCompanyType">
										<span style="color:#FDA23C;margin-right:10px;font-size:14px">{{orderDetail.DeliveryInfo.InCityDeliveryStateDisplay}}</span>
										<el-button type="text"  v-if="![1,6].includes(orderDetail.DeliveryInfo.InCityDeliveryState)" @click="looklocation">查看配送员位置</el-button>
									</div>
								</div>
								<el-timeline style="height:180px;overflow:auto;margin-top:10px" v-if="orderDetail.DeliveryInfo.InCityDeliveryCompanyType==1">
									<el-timeline-item v-for="(j,index) in orderDetail.DeliveryInfo.OrderInCityDeliveryRecordList" :key="index"
										:timestamp="j.AddTime" placement="top">
										<el-card style="padding:0 5px">
											<p style="color:#303133">{{j.InCityDeliveryStateValue}}</p>
											<p style="margin-top:5px;word-wrap:break-word;color:#606266">{{j.Remark}}</p>
										</el-card>
									</el-timeline-item>
								</el-timeline>

								<div v-else style="text-align: center;line-height:180px;">暂未跟踪到物流信息</div>

							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<!-- 一些信息 -->
			<div class="mainCon" style="margin-top:10px">
				<div class="info-con" style="width:100%">
					<div>
						<!-- 已完成 -->
						<div style="margin-bottom: 15px;">
							<span style="font-weight: bold;">配送信息</span>
							<!-- 已完成才有 -->
						</div>
						<div v-if="orderDetail.SendType==0">配送方式：快递发货</div>
						<div v-else-if="orderDetail.SendType==1">配送方式：到店自提</div>
						<div v-else-if="orderDetail.SendType==2">配送方式：同城配送</div>
						<!-- {{orderDetail}} -->
						<!-- <div v-if="orderDetail.SendType==2">配送门店：{{orderDetail.DeliveryShopName}}</div>
						<div v-if="orderDetail.SendType==2&&orderDetail.PayState!=0">
							<span>期望送达时间：
								<span>{{orderDetail.EstimatedDeliveryTimeValue}}</span>
							</span>
						</div> -->

						<div v-if='orderDetail.ShopName && mallInfo.IsMoreShop'>支付业绩归属门店：{{orderDetail.ShopName}}</div>
						<!-- <div>下单门店：{{orderDetail.ShopName}}</div> -->
						<!-- <div v-if="orderDetail.OrderState ==4 || orderDetail.OrderState ==5"> -->
						<div v-if="orderDetail.SettlementShopName && mallInfo.IsMoreShop">
							结算业绩归属门店：{{orderDetail.SettlementShopName}}</div>
						<!-- 已发货才有的 -->
						<!-- <div
							v-if="orderDetail.SendType==1&&orderDetail.PickUpShopName&&orderDetail.SendTime!='2000-01-01 00:00:00'">
							提货门店：{{orderDetail.PickUpShopName}}</div>
						<div
							v-if="orderDetail.SendType==1&&orderDetail.SendTime&&orderDetail.SendTime!='2000-01-01 00:00:00'">
							提货时间：{{orderDetail.SendTime}}</div> -->
						<!-- </div> -->
					</div>
					<div>
						<div style="margin-bottom: 15px;">
							<span style="font-weight: bold;margin-bottom: 15px;"
								v-if="orderDetail.SendType==0||orderDetail.SendType==2">收货信息</span>
							<span style="font-weight: bold;margin-bottom: 15px;" v-else-if="orderDetail.SendType==1">提货信息</span>
							<buttonPermissions :datas="'edit'" v-if="!orderDetail.IsExistsRoutuanProduct||!IsDockingRoutuan">
								<span style="color:blue;margin-left:10px;cursor: pointer;" 
								@click="editAdress" v-if="(orderDetail.OrderState==2 || orderDetail.OrderState==3)">修改</span>
							</buttonPermissions>
              <span class="tag-read" v-if="orderDetail.SendType==0||orderDetail.SendType==2"
								style="color:blue;margin-left:10px;cursor: pointer;" 
								:data-clipboard-text="orderDetail.ReceiveName+'\xa0'+'\xa0'+orderDetail.ReceivePhone+'\xa0'+'\xa0'+orderDetail.ReceiveProvince+
								orderDetail.ReceiveCity+orderDetail.ReceiveArea+orderDetail.ReceiveAddress" 
								@click="duplication">复制</span>
							<span class="tag-read" v-else-if="orderDetail.SendType==1"
							 style="color:blue;margin-left:10px;cursor: pointer;" 
							 :data-clipboard-text="orderDetail.ReceiveName+'\xa0'+'\xa0'+orderDetail.ReceivePhone+'\xa0'+'\xa0'+orderDetail.SelectShopProvince+
							 orderDetail.SelectShopCity+orderDetail.SelectShopArea+orderDetail.SelectShopAddress" 
							 @click="duplication">复制</span>
						</div>
						<div v-if="orderDetail.SendType==0&&orderDetail.OrderState==3&&orderDetail.IsShowAddressChange" style="color:#FDA23CFF">(信息变更，订单轨迹中可查看变更详情)</div>
						<div v-if="orderDetail.SendType==0||orderDetail.SendType==2">
							<div>收货人：{{orderDetail.ReceiveName}}</div>
							<div class="flexRow">收货手机号：{{orderDetail.ReceivePhone}}
								<eyes-hide v-model="orderDetail.ReceivePhone" :data="{OrderNo:orderDetail.OrderNo}" range="order"></eyes-hide>
							</div>
							<div class="shipping-address">
								收货地址：{{orderDetail.ReceiveProvince}}{{orderDetail.ReceiveCity}}{{orderDetail.ReceiveArea}}{{orderDetail.ReceiveAddress}}
							</div>
						</div>
						<div v-else-if="orderDetail.SendType==1">
							<div>提货人：{{orderDetail.ReceiveName}}</div>
							<div>提货手机号：{{orderDetail.ReceivePhone}}</div>
							<el-tooltip class="item" effect="dark" placement="top" popper-class="popperClass">
								<div slot="content" style="max-width:400px">
									{{orderDetail.SelectShopName}} ({{orderDetail.SelectShopProvince}}{{orderDetail.SelectShopCity}}{{orderDetail.SelectShopArea}}{{orderDetail.SelectShopAddress}})
								</div>
								<div class="shipping-address2">
									选择提货门店：{{orderDetail.SelectShopName}}({{orderDetail.SelectShopProvince}}{{orderDetail.SelectShopCity}}{{orderDetail.SelectShopArea}}{{orderDetail.SelectShopAddress}})
								</div>
							</el-tooltip>
							<div v-if="orderDetail.SendType==1&&orderDetail.SendTime!='2000-01-01 00:00:00'">
								<el-tooltip class="item" effect="dark" placement="top" popper-class="popperClass">
									<div slot="content" style="max-width:400px">
										{{orderDetail.PickUpShopName}} ({{orderDetail.PickUpShopProvince}}{{orderDetail.PickUpShopCity}}{{orderDetail.PickUpShopArea}}{{orderDetail.PickUpShopAddress}})
									</div>
									<div class="shipping-address2">
										实际提货门店：{{orderDetail.PickUpShopName}}({{orderDetail.PickUpShopProvince}}{{orderDetail.PickUpShopCity}}{{orderDetail.PickUpShopArea}}{{orderDetail.PickUpShopAddress}})
									</div>
								</el-tooltip>
								<div>提货时间：{{orderDetail.SendTime}}</div>
							</div>
						</div>
						<div class="flexRow" v-if="orderDetail.RealName">实名信息：
							<span>{{orderDetail.RealName}}</span>
							<span v-if="orderDetail.IdCardNo">（{{orderDetail.IdCardNo}}）</span>
							<eyes-hide v-model="orderDetail.IdCardNo" :data="{OrderNo:orderDetail.OrderNo}" type="IdCard"></eyes-hide>
						</div>
					</div>
					<div>
						<div style="font-weight: bold;margin-bottom: 15px;">买家信息</div>
						<div>
							<span>买家信息：{{orderDetail.CustomerNameDisplay}}</span>
							<!-- v-if="!orderDetail.SupplierMallId" -->
							<el-button type='text' style='margin-left:5px' @click='goCustmer'>联系客户</el-button>
						</div>
						<div v-if="orderDetail.CustomerVipRoleName">会员级别：{{orderDetail.CustomerVipRoleName}}</div>
						<div v-if="!orderDetail.CustomerVipRoleName">会员级别：--</div>
						<div v-if="orderDetail.CustomerDistributorRoleName">
							推手：{{orderDetail.CustomerDistributorRoleName}}</div>
						<div v-if="!orderDetail.CustomerDistributorRoleName">推手：--</div>
					</div>
					<div>
						<!-- v-if="orderDetail.OrderType!=2" -->
						<div>
							<div style="font-weight: bold;margin-bottom: 15px;">收益与提成</div>
							<div>锁粉店员：{{orderDetail.EmployeeName}}
								<span v-if="orderDetail.EmployeePhone">({{orderDetail.EmployeePhone}})</span>
							</div>
							<div>店员提成：￥{{orderDetail.EmployeeAwardMoney }}</div>
							<div>推手:
								<span>{{orderDetail.MemberName?orderDetail.MemberName: '--'}}</span>
								<span v-if="orderDetail.MemberPhone">({{orderDetail.MemberPhone}})</span>
							</div>
							<div>自营零售收益：￥{{orderDetail.MemberAwardMoney}}</div>
							<div>直推销售收益：￥{{orderDetail.MemberRecommendAwardMoney}}</div>
							<div v-if="isAgentModel==1">间推销售收益：￥{{orderDetail.MemberIndirectRecommendAwardMoney}}</div>
							<div v-if="isAgentModel==1">团队收益：￥{{orderDetail.MemberGroupAwardMoney}}</div>
						</div>
						<!-- 待审单 -->
						<!-- <div style="color:#E6A23C" v-else>分销礼包订单不发放任何收益或提成</div> -->

					</div>
					<div v-if="orderDetail.SupplierMallId">
						<div style="font-weight: bold;margin-bottom: 15px;">供货商</div>
						<div v-if="orderDetail.SupplierMallName">供货商：{{orderDetail.SupplierMallName }}</div>
						<div v-if="orderDetail.SupplierMallCustomerServicePhone">
							客服电话：{{orderDetail.SupplierMallCustomerServicePhone}}</div>
						<div v-else>客服电话：--</div>
						<div v-if="orderDetail.SupplierMallCustomerServiceWx">
							客服微信：{{orderDetail.SupplierMallCustomerServiceWx}}</div>
						<div v-else>客服微信：--</div>
					</div>

				</div>
			</div>

			<!-- 商品表格 -->
			<div class="mainCon">
				<el-table :data="productsData" style="width:100%;" default-expand-all :row-class-name="setClassName">
					<el-table-column type="expand">
						<template slot-scope="scope">
							<div style="overflow:hidden;" v-if="scope.row.MixList">
								<!--  -->
								<div class="reply-expand" :key="index" v-for="(item ,index) in scope.row.MixList">
									<div class="product-info">
										<img v-if="item.ImgUrlComplete" :src="item.ImgUrlComplete">
										<svg-icon v-else icon-class="noImgIcon" />
										<div style="min-height: auto;">
											<div
												style="display:flex;justify-content:space-between;align-items:flex-start;">
												<div style="width:280px;">
													<pre
														style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{item.ProductName}}
													</pre>
												</div>
											</div>
											<div v-if="!orderDetail.SupplierMallId">{{item.ProductNo}}</div>
										</div>
									</div>
									<div class="product-spec">
										<div style="padding-bottom:10px;">
											<span v-if="item.SpecValue || item.SpecValue2">
												<span v-if="item.SpecValue">{{item.SpecValue}}</span>
												<span v-if="item.SpecValue2">,{{item.SpecValue2}}</span>
											</span>
											<span v-else>默认规格</span>
										</div>
										<span v-if="!orderDetail.SupplierMallId">{{item.Barcode}}</span>

									</div>
									<div class="product-msg">
										<div>{{item.UnitPriceDisplay}}</div>
									</div>
									<div class="product-msg">
										<div>0</div>
									</div>
									<div class="product-msg">
										<div>{{item.ProductCount}}</div>
									</div>
									<div class="product-msg">
										<div>{{(item.UnitPriceDisplay*item.ProductCount).toFixed(2)}}</div>
									</div>
									<div class="product-msg">
										<div>0</div>
									</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="date" label="商品" width="400">
						<template slot-scope="scope">
							<!-- @click="lookCounpGoods(scope.row)" -->
							<div class="product-info" v-if="scope.row.ProductType==1">
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
								<svg-icon v-else icon-class="noImgIcon" />
								<div style="min-height: auto;">
									<div style="display:flex;justify-content:space-between;align-items:flex-start;">
										<div style="color: #f00;font-size: 12px;width:40px"
											v-if="scope.row.DealType==3">[赠品]</div>
										<div style="color: #f00;font-size: 12px;width:40px"
											v-if="scope.row.ProductType==1">[组合]</div>
										<div style="width:280px;">
											<pre
												style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}
											</pre>
										</div>
									</div>
									<!-- 若为分销采购单，不显示商品编码 -->
									<div v-if="!orderDetail.SupplierMallId">{{scope.row.ProductNo}}</div>
								</div>
							</div>

							<div class="product-info" v-if="scope.row.ProductType!=1">
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
								<svg-icon v-else icon-class="noImgIcon" />
								<div>

									<div style="display:flex;justify-content:space-between;align-items:center;">
										<div style="color: #f00;font-size: 12px;width:40px"
											v-if="scope.row.DealType==3">[赠品]</div>
										<div>{{scope.row.ProductName}}</div>
									</div>
									<!-- 若为分销采购单，不显示商品编码 -->
									<div v-if="!orderDetail.SupplierMallId">{{scope.row.ProductNo}}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="规格" width="120">
						<template slot-scope="scope">
							<span v-if="scope.row.SpecValue || scope.row.SpecValue2">
								<span v-if="scope.row.SpecValue">{{scope.row.SpecValue}}</span>
								<span v-if="scope.row.SpecValue2">,{{scope.row.SpecValue2}}</span>
							</span>
							<span v-else>默认规格</span>
							<!-- 若为分销采购单，不显示规格条形码-->
							<div v-if="!orderDetail.SupplierMallId">{{scope.row.BarCode }}</div>
						</template>
					</el-table-column>
					<el-table-column prop="address" label="商品价格" width="120">
						<template slot-scope="scope">
							<div>{{scope.row.ProductPrice}}</div>
							<div>
								<div v-if="scope.row.ActivityCommunityGroupId>0">社群专享价</div>
								<div v-if="scope.row.VipDiscount">会员折扣:{{scope.row.VipDiscount/10}}折</div>
								<div v-if="scope.row.ActivityDiscountRate">限时折扣:{{scope.row.ActivityDiscountRate/10}}折
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="ReduceMoney" label="优惠金额" width="120"></el-table-column>
					<el-table-column prop="ProductCount" label="购买数量" width="120"></el-table-column>
					<el-table-column prop="address" label="小计(元)" width="120">
						<template slot-scope="scope">
							<div>{{(scope.row.ProductPrice*scope.row.ProductCount+scope.row.ReduceMoney).toFixed(2)}}
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="ProductCashbackMoney" label="返现退款" width="120"></el-table-column> -->
					<el-table-column prop="Profit" label="收益" width="120"></el-table-column>
					<el-table-column label="发货状态" width="120">
						<template slot-scope="scope">
							<span v-if='scope.row.SendState == 0' style="color: #F56C56">未发货</span>
							<span v-if='scope.row.SendState == 1' style="color: #409EFF">已发货</span>
							<span v-if='scope.row.SendState == 2'>无需发货</span>
						</template>
					</el-table-column>
					<el-table-column prop="address" label="退款信息" min-width="120">
						<template slot-scope="scope" style="font-size: 12px;">
							<!-- 分销采购单，不可主动退款 -->
							<div v-if="orderDetail.SupplierMallId">分销采购单，不可主动退款</div>
							<div v-if="!orderDetail.SupplierMallId">
								<div v-if="orderDetail.OrderType!=1&&orderDetail.OrderState==1">买家未支付，无需主动退款</div>

								<div style="color:#409EFF" v-if="orderDetail.OrderType!=1&&scope.row.IsSupport">
									<div>退款中</div>
									<div style="color:#909399;font-size:12px;">商品退款中，不可主动退款</div>
								</div>

								<div v-if="orderDetail.OrderType!=1&&orderDetail.OrderState==8"
									style="color:#909399;font-size: 12px;">
									订单已关闭,不可继续退款
								</div>

								<div v-if="scope.row.DealType==3 ||orderDetail.OrderType==1"
									style="color:#909399;font-size: 12px;">
									赠品不支持退款
								</div>

								<div v-if="orderDetail.OrderState == 9" style="color:#909399;font-size: 12px;">
									拼团订单，成团前不可主动退款
								</div>

								<div v-if="orderDetail.OrderType==5" style="color:#909399;font-size: 12px;">
									助力活动单不支持退款
								</div>
								<!-- <div v-if="scope.row.DealType== 2 && scope.row.RealMoney==0" style="color:#909399;font-size: 12px;">
									商品全额积分支付，不支持退款
								</div> -->
								<div
									v-if="scope.row.DealType!=3&&orderDetail.OrderType!=1&&orderDetail.OrderState!=1&&
									orderDetail.OrderState!=9&&orderDetail.OrderState!=8&&(!scope.row.IsSupport)&&orderDetail.OrderType!=5&&
									((IsDockingRoutuan&&(orderDetail.RoutuanDealState!=0))||!IsDockingRoutuan)">
									<div v-if="scope.row.RefundCount!=scope.row.ProductCount">
										<div v-if="scope.row.RefundMoney">已退款￥{{scope.row.RefundMoney}}</div>
										<span class="self-refuse" style="margin-right:5px;"
											v-if="orderDetail.StoreId==0"
											@click="refuseMoeny(scope.row,false)">主动退款</span>

										<buttonPermissions :datas="'orderVirtualRefund'" v-if="orderDetail.PayBy==5||orderDetail.PayBy==9">
											<span class="self-refuse" @click="refuseMoeny(scope.row,true)">虚拟退款</span>
										</buttonPermissions>

									</div>
									<div style="color:#909399" v-if="scope.row.RefundCount==scope.row.ProductCount">
										商品可退数量为0，不可继续退款</div>
								</div>

							</div>


						</template>
					</el-table-column>
				</el-table>

				<div class="comeback-money">
					<p>商品合计:{{productAll}}</p>
					<p>优惠合计:{{discoutAll}}</p>
					<span
						style="color:rgb(64, 158, 255);cursor: pointer;display: inline-block;width:80px;text-align: right;"
						@click="lookBelifDetails">优惠明细</span>
					<p>运费:{{orderDetail.FreightMoney}}</p>
					<p style="color:rgb(64, 158, 255)" v-if="ChangeFreightMoney">（运费改价：运费
						<span v-if="ChangeFreightMoney>0">加</span>
						<span v-if="ChangeFreightMoney<0">减</span>
						{{ChangeFreightMoney}}）
					</p>
					<p class="allMoney">
						<span>订单合计: </span>
						<span style="color:#F56C6C ">{{orderAll}}</span> 
					</p>
					<div>{{orderDetail.PayByDisplay}}</div>
					<!-- <p>返现退款: <span style="color:#F56C6C ">{{moneyAll}}</span></p> -->
				</div>
			</div>

			<!-- 订单轨迹 -->
			<div class="refund-record">
				<div class="title">订单轨迹</div>
				<div class="refund-timeline" v-if="orderDetail.RecordList && orderDetail.RecordList.length">
					<el-timeline>
						<el-timeline-item 
						v-for="(j,index) in orderDetail.RecordList " 
						:key="index"
						v-if="j&&j.AddTime"
						:timestamp="j.AddTime" 
						placement="top">
							<el-card style="padding:0 20px">
								<h4 v-if="j.OperatorType==1">商家</h4>
								<h4 v-if="j.OperatorType==2">买家</h4>
								<h4 v-if="j.OperatorType==3">系统</h4>
								<p style="margin-top:5px;word-wrap:break-word;">{{j.Remark}}</p>
							</el-card>
						</el-timeline-item>
					</el-timeline>
				</div>
			</div>

			<!-- 主动退款的弹框 -->
			<el-dialog :title="title" :visible.sync="returnselfVisivble" width="850px" v-loading="loading">
				<div class="remind_l" v-if="isVirtualRefund">
					<svg t="1641979790297" 
					class="icon" 
					viewBox="0 0 1024 1024" 
					version="1.1" 
					xmlns="http://www.w3.org/2000/svg" p-id="2154" width="16" height="16">
					<path d="M944.256 926.4c-31.04 0-820.8 0-865.792 0-44.288 0-65.856-52.16-46.4-91.968C58.688 780.096 441.216 136.064 465.088 90.176c21.376-41.216 72.384-41.92 93.76 0 32.576 63.808 413.248 704.96 432 742.272C1014.464 879.104 984.576 926.4 944.256 926.4L944.256 926.4zM466.496 332.736l30.464 319.616 45.632 0 30.528-319.616L466.496 332.736 466.496 332.736zM573.12 698.112 466.496 698.112l0 91.264 106.624 0L573.12 698.112 573.12 698.112z" p-id="2155" fill="#F56C6C"></path></svg>
					<span style="margin-top:5px">注意:您当前正在使用虚拟退款功能，此功能无法通过系统退款至买家支付账户，请通过线下转账完成款项退回</span>
				</div>
				<!-- 新增提示 -->
				<div class="remind" v-if="IsShowSpellGroupTip">
					<span style="margin-left:10px">提示:该订单参与了拼团抢购抽奖，且目前订单所在地的拼团已开奖，未抢中奖励已发放,订单退款成功，不会影响已发放的奖励</span>
				</div>
				<el-table :data="refuseTable" style="width: 100%">
					<el-table-column prop="date" label="商品" width="400">
						<template slot-scope="scope">
							<div class="product-info" @click="lookCounpGoods(scope.row)"
								v-if="scope.row.ProductType==1">
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
								<svg-icon v-else icon-class="noImgIcon" />

								<div style="min-height: auto;">
									<div style="display:flex;justify-content:space-between;align-items:flex-start;">
										<div style="color: #f00;font-size: 12px;width:40px"
											v-if="scope.row.ProductType==1">[组合]</div>

										<div style="width:280px;">
											<pre
												style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}
											</pre>
										</div>
									</div>
									<div>{{scope.row.ProductNo}}</div>
								</div>
							</div>

							<div class="product-info" v-else>
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
								<svg-icon v-else icon-class="noImgIcon" />
								<div>
									<div style="display:flex;justify-content:space-between;align-items:center;">
										<div>{{scope.row.ProductName}}</div>
										<!-- <i v-if="scope.row.ProductType==0" class="el-icon-edit-outline" @click="productEdit(scope.row)"></i> -->
									</div>
									<div>{{scope.row.ProductNo}}</div>
								</div>
							</div>


						</template>

					</el-table-column>
					<el-table-column prop="name" label="规格">
						<template slot-scope="scope">
							<span v-if="scope.row.SpecValue">{{scope.row.SpecValue}}</span>
							<span v-if="scope.row.SpecValue2">,{{scope.row.SpecValue2}}</span>
							<span v-if="!scope.row.SpecValue&&(!scope.row.SpecValue2)">默认规格</span>
							<div>{{scope.row.BarCode}}</div>
						</template>
					</el-table-column>

					<el-table-column prop="RemainCount" label="可退数量">
					</el-table-column>
					<el-table-column prop="name" label="退款数量" width="110">
						<template slot-scope="scope">
							<input v-model="scope.row.RefundCount" @input="refundMoneysChange"
								style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
								oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number" />
			
						</template>
					</el-table-column>
					<el-table-column prop="name" label="退款金额">
						<template slot-scope="scope">
							<div>￥{{refuseMoneys}}</div>
							<div style="color:rgb(144, 147, 153);font-size: 12px;"
								v-if="scope.row.IsRefundFreight&&(scope.row.RemainCount==scope.row.RefundCount ||(scope.row.RemainMoney==0&&Number(scope.row.RefundCount)>0))">
								(含运费{{scope.row.FreightMoney}}元)</div>
							<div style="color:rgb(144, 147, 153);font-size: 12px;" v-else>(含运费0元)</div>
						</template>
					</el-table-column>
				</el-table>

				<div style="text-align: center;margin-top: 20px;">
					<el-button @click="returnselfVisivble=false" style="width:120px;margin-right:15px">关闭</el-button>
					<el-button type="primary" @click="sureRefuse" style="width:120px" :loading="loading">确认退款
					</el-button>
				</div>

			</el-dialog>

			<!-- 订单未发货 点击退款 -->
			<el-dialog :visible.sync="nosendVisivble" width="500px">
				<div class="sendOrders">该订单为礼包授权单，退款成功后，将自动取消授权；
					若订单已经提交给仓库进行发货，主动退款前，请告知相关发货人员，避免商品多发。是否确认继续退款？</div>
				<div style="text-align: center;margin-top: 20px;">
					<el-button @click="nosendVisivble=false" style="width:120px;margin-right:15px">关闭</el-button>
					<el-button type="primary" @click="returnselfVisivble=true;nosendVisivble=false" style="width:120px"
						:loading="loading">继续退款</el-button>
				</div>
			</el-dialog>
			<!-- 订单已发货或 已完成 -->
			<el-dialog :visible.sync="successVisivble" width="400px">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<div class="sendOrders">该订单为礼包授权单，退款成功后，请确认是否需要同时取消授权？</div>
					<el-form-item prop="resource">
						<el-radio-group v-model="ruleForm.resource">
							<el-radio :label="1">取消授权</el-radio>
							<el-radio :label="0">不取消授权</el-radio>
						</el-radio-group>
					</el-form-item>

					<div style="text-align: center;margin-top: 20px;">
						<el-button @click="successVisivble=false" style="width:120px;margin-right:15px">关闭</el-button>
						<el-button type="primary" @click="submitForm('ruleForm')" style="width:120px"
							:loading="loading">继续退款</el-button>
					</div>
				</el-form>
			</el-dialog>

			<!-- 已审单点击退款 -->
			<el-dialog :visible.sync="checkVisivble" width="500px">
				<div class="sendOrders">若订单已经提交给仓库进行发货，主动退款前，请告知相关发货人员，避免商品多发。</div>
				<div style="text-align: center;margin-top: 20px;">
					<el-button @click="checkVisivble=false" style="width:120px;margin-right:15px">关闭</el-button>
					<el-button type="primary" @click="checkVisivble=false;returnselfVisivble=true" style="width:120px"
						:loading="loading">继续退款</el-button>
				</div>
			</el-dialog>

			<!-- //修改收货地址的弹窗 -->
			<!-- :before-close="modelClose" -->
			<el-dialog :visible.sync="editAddressModel" title="修改收货信息" width="50%" v-loading="loading">
				<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" :rules="rules">
					<el-form-item label="收货人姓名" prop="Name">
						<el-input placeholder="请输入内容" v-model="ruleForm.Name" clearable style='width:300px;'></el-input>
					</el-form-item>
					<el-form-item label="收货人手机" prop="phone">
						<el-input placeholder="请输入手机号" style='width:300px;' v-model="ruleForm.phone" clearable
							maxlength="11"
							onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
							onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
						</el-input>
					</el-form-item>
					<el-form-item label="收货地址" prop="AddressArr">
						<el-cascader style="width: 300px;" :props="props" :options="dataList" clearable
							@change="handleAddressChange" v-model="ruleForm.AddressArr" />
					</el-form-item>

					<el-form-item style="box-sizing: border-box" prop="Address">
						<el-col :span="14">
							<el-input type="textarea" v-model="ruleForm.Address" rows="4" placeholder="请输入详细地址">
							</el-input>
						</el-col>
					</el-form-item>
				</el-form>
				<div style="text-align: center;margin-top: 10px;">
					<el-button @click="modelClose" style="width:120px;">关闭</el-button>
					<el-button type="primary" @click="saveAddressinfo('ruleForm')" style="width:120px;">确认修改</el-button>
				</div>

			</el-dialog>

			<!-- 到店自提，不可换地址 -->
			<el-dialog :visible.sync="noeditesVisivble" width="350px">

				<div class="sendOrders" style="text-align: center;">到店自提订单不可修改提货信息</div>
				<div style="text-align: center;margin-top: 20px;">
					<el-button @click="noeditesVisivble=false" style="width:100px;margin-right:15px">关闭</el-button>
				</div>
			</el-dialog>

			<!-- 优惠明细弹框 -->
			<el-dialog title="订单优惠明细" :visible.sync="orderdetailsVisivble" width="850px">
				<el-table :data="ordermenyData" style="width: 100%">
					<el-table-column prop="ActivityTypeDisplay" label="活动类型"></el-table-column>
					<el-table-column prop="ActivityName" label="活动名称"></el-table-column>
					<el-table-column prop="ReduceMoney" label="优惠金额"></el-table-column>
				</el-table>
			</el-dialog>

			<!-- 组合商品查看子商品 -->
			<el-dialog title="组合商品明细" :visible.sync="contactVisivble" width="850px">
				<el-table :data="contactData" style="width: 100%">
					<el-table-column prop="date" label="商品名称" width="250">
						<template slot-scope="scope">
							<div class="product-info">
								<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
								<svg-icon v-else icon-class="noImgIcon" />
								<div>{{scope.row.ProductName}}</div>
							</div>
						</template>

					</el-table-column>
					<el-table-column prop="date" label="规格">
						<template slot-scope="scope">
							<div v-if="scope.row.SpecValue||scope.row.SpecValue2">
								{{scope.row.SpecValue}}
								<span v-if="scope.row.SpecValue2">,</span>
								{{scope.row.SpecValue2}}
							</div>
							<div v-else>默认规格</div>
						</template>

					</el-table-column>

					<el-table-column prop="ProductCount" label="组合内数量"></el-table-column>
					<el-table-column prop="UnitPriceDisplay" label="组合内售价"></el-table-column>
				</el-table>

			</el-dialog>


			<!-- 订单备注弹框 -->
			<el-dialog title="订单备注" :visible.sync="ordermarkDialogVisible" width="650px">
				<el-form :model="orderForm" ref="orderForm" :rules="rules" label-width="120px">
					<el-form-item label="订单备注 :">
						<el-input v-model="orderForm.orderMark" maxlength="200" type="textarea" style="width:300px;"
							clearable></el-input>
					</el-form-item>
					<el-form-item label="图片备注：">
						<div class="img-remark">
							<!-- <div> -->
								<div class="remark-re" v-for="(v,i) in orderForm.SystemImgRemark" :key="v">
									<img :src="imgurl+v" style="width:100%;height:100%" alt="">
									<div class="remark-close" @click="removeMarkimg(i)">
										<i class="el-icon-error" style="font-size:14px"></i>
									</div>
								</div>
								<el-upload :action="uploadimgApi" :show-file-list="false" :on-success="handleAvatarSuccess" class="upload-box"
									style="margin: 0px 10px 10px 0px;" :before-upload="beforeAvatarUpload" accept="image/*" :multiple="true" :limit="9"
									list-type="picture-card" v-if="orderForm.SystemImgRemark.length<9">
									<i class="el-icon-plus" style="font-size:20px"></i>
								</el-upload>
							<!-- </div> -->
						</div>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="ordermarkDialogVisible=false">关 闭</el-button>
					<el-button type="primary" @click="sureOrdermark('orderForm')" :loading="loading">确 认</el-button>
				</span>
			</el-dialog>


			<!-- 快递 -->
			<el-dialog title="修改物流信息" :visible.sync="modifySendVisible" width="700px" class="send-dialog" @close="closeExpress">
				<div class='old-send-mess'>
					<span>原物流公司：{{this.sendForm.oldExpressValue}}</span>
					<br>
					<span>原物流单号：{{this.sendForm.oldExpressNo}}</span>
				</div>
				<el-form :model="sendForm" ref="sendForm" :rules="sendRules" v-loading='sendLoading'>
					<el-form-item label="物流公司 :" prop="ExpressId">
						<el-select v-model="sendForm.ExpressId" placeholder="输入物流公司名称查询" filterable style="width:300px;">
							<el-option v-for="(i,index) in  ExpressList" :key="index" :label="i.ExpressName"
								:value="i.Id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="物流单号 :" prop="ExpressNo">
						<el-input v-model="sendForm.ExpressNo" placeholder="请输入物流单号" style="width:300px;"></el-input>
					</el-form-item>
				</el-form>
				<div style="text-align: center;">
					<el-button @click="modifySendVisible= false" style="width:120px">取 消</el-button>
					<el-button type="primary" @click="submitModifySends('sendForm')" style="width:120px">确认修改
					</el-button>
				</div>
			</el-dialog>

			<el-dialog title="取消呼叫" :visible.sync="cancalclllShow" width="500px" top="45vh" v-loading="cancalclllLoading"
			 @close="cancalclllShow=false,CancelCode=null">
				<div class="flexRow">
					<div style="flex-shrink:0">
						<span style="color:#f56c6c;margin-right:4px">*</span>
						<span>取消原因：</span>
					</div>
					<el-select v-model="CancelCode" placeholder="请选择取消原因" style="width:100%">
						<el-option v-for="(v,i) in cancalreasonlist" :key="i" :value="v.value" :label="v.label"></el-option>
					</el-select>
				</div>
				<div style="margin-top:30px;text-align:right">
					<el-button @click="cancalclllShow=false,CancelCode=null">取消</el-button>
					<el-button type="primary" @click="confirmcancal">确认</el-button>
				</div>
			</el-dialog>

			<el-dialog title="订单加小费" :visible.sync="addtipShow" v-loading="addtipLoading" width="500px" top="45vh"
			  @close="addtipShow=false,InCityDeliveryGratuityFee=null">
				<div>本笔订单已累计加小费{{tipMsg.AddGratuityTimes}}次，小费累计金额
					<span style="color:#f56c6c">{{tipMsg.TotalGratuityFee}}元</span>
				</div>
				<div class="flexRow" style="margin-top:20px">本次小费金额：
					<el-input v-model="InCityDeliveryGratuityFee" style="width:200px;margin:0px 10px" placeholder=""></el-input>元
				</div>
				<div style="margin-top:30px;text-align:right">
					<el-button @click="addtipShow=false,InCityDeliveryGratuityFee=null">取消</el-button>
					<el-button type="primary" @click="confirmAddtip">确认</el-button>
				</div>
			</el-dialog>


		</div>

	</div>
</template>

<script>
	import {
		orderlookDetail,
		orderIndexStar,
		orderlookExpress,
		ordereditReceive,
		orderRemark,
		ordererefundInit,
		ordererefundsave,
		detailcomboProduct,
		detailcomboDetail,
		orderIndexinit,
		orderEditExpress,
		inCityDeliverycancelCall,
		inCityDeliverydeductionFee,
		inCityDeliveryaddGratuityFeeinit,
		inCityDeliveryaddGratuityFee,
		inCityDeliverysendCall,
	} from '@/api/goods'
	import {
		XJChatGetLoginCert
	} from '@/api/login.js'
	import axios from 'axios'
	import config from '@/config/index'
	import Clipboard from 'clipboard'//复制
	import {
		mapGetters
	} from 'vuex'
	
	import eyesHide from "@/components/eyesHide"
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions,
			eyesHide
		},
		computed: {
			...mapGetters([
				"mallInfo",
				'IsDockingRoutuan',
			])
		},
		data() {
			var checkPhone = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('手机号不能为空'));
				} else {
					const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
					console.log(reg.test(value));
					if (reg.test(value)) {
						callback();
					} else {
						return callback(new Error('请输入正确的手机号'));
					}
				}
			};


			return {
				uploadimgApi:config.UPLOAD_IMG,
				imgurl:config.IMG_BASE,
				text:"复制1",
				show:"",
				IsShowSpellGroupTip:false,
				title:"主动退款",
				isVirtualRefund: false,
				startCount: 1,
				detailoading: false,
				refuseMoneys: 0,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				productAll: 0,
				discoutAll: 0,
				moneyAll: 0,
				orderAll: 0,
				ordermarkDialogVisible: false,
				orderForm: {
					orderMark: '',
					SystemImgRemark:[],
				},
				props: {
					label: 'name',
					value: 'id',
					children: 'child'
				},
				dataList: [],
				expressInfo: {},
				orderDetail: {},
				startNum: null,
				loading: false,
				productsData: [{
					address: ''
				}],
				returnselfVisivble: false,
				refuseTable: [],
				nosendVisivble: false,
				successVisivble: false,
				ruleForm: {
					resource: 0,
					OrderNo: '',
					Name: '',
					phone: '',
					Province: '',
					City: '',
					Area: '',
					Address: '',
					AddressArr: [],
				},
				rules: {
					resource: [{
						required: true,
						message: '请选择是否取消授权',
						trigger: 'change'
					}],
					Name: [{
							required: true,
							message: '请输入收货人姓名',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 15,
							message: '长度在 1 到 15 个字符',
							trigger: 'blur'
						}
					],
					phone: [{
						validator: checkPhone,
						trigger: 'blur'
					}],
					AddressArr: [{
						required: true,
						message: '请选择收货地址',
						trigger: 'change'
					}],
					Address: [{
							required: true,
							message: '请输入详细地址',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 100,
							message: '长度在 1 到 100 个字符',
							trigger: 'blur'
						}
					],
					orderMark: [{
							required: true,
							message: '请填写订单备注',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 200,
							message: '长度在 1 到 200 个字符',
							trigger: 'blur'
						}
					],

				},
				checkVisivble: false,
				editAddressModel: false,
				ProvinceList: [],
				CityList: [],
				AreaList: [],
				noeditesVisivble: false,
				orderdetailsVisivble: false,
				ordermenyData: [],
				contactVisivble: false,
				contactData: [],
				OrderNo: null,
				RecordList: [],
				OrderDetailId: null,
				ChangeFreightMoney: 0,
				isAgentModel: 0,
				activeTabName: 'tab1',
				imgsList: [],
				marginIndex: 0,
				marginIndexRight: false,
				bages: {
					current: 1,
					size: 5,
					rest: 0
				},
				widthUp1366: false,
				modifySendVisible: false,
				sendLoading: false,
				ExpressList: [],
				sendForm: {
					ExpressId: '',
					oldExpressValue: '',
					oldExpressNo: '',
					selectedList: [],
					ExpressNo: '',
					DetailId: null,
				},
				sendRules: {
					ExpressId: [{
						required: true,
						message: '请选择物流公司',
						trigger: 'change'
					}],
					ExpressNo: [{
						required: true,
						message: '请输入物流单号',
						trigger: 'blur'
					}]
				},
				CustomerRemark:[],
				cancalreasonlist:[
					{value:300,label:'计划有变，暂时不需要寄件了'},
					{value:302,label:'填错订单信息，取消后重新提交'},
					{value:303,label:'配送员要求取消'},
					{value:304,label:'暂时无法提供待配送商品'},
					{value:309,label:'配送员上门时间太长'},
					{value:312,label:'没有配送员接单'},
					{value:313,label:'其他原因'},
				],
				CancelCode:null,
				cancalclllShow:false,
				cancalclllLoading:false,
				addtipShow:false,
				addtipLoading:false,
				tipMsg:{},
				InCityDeliveryGratuityFee:'',
			}
		},
		mounted() {
			this.initScreenWidth()
		},
		beforeMount() {
			this.isAgentModel = window.localStorage.getItem('mlmzDistributionModel')
			this.getDataList()
			this.OrderNo = this.$route.query.Id;
			this.getOrderdata()
		},
		methods: {
			removeMarkimg(i){
				this.orderForm.SystemImgRemark.splice(i,1)
			},
			handleAvatarSuccess(list){
				this.orderForm.SystemImgRemark = this.orderForm.SystemImgRemark.concat(list).filter((v,i)=>{
					return i<9
				})
			},
			beforeAvatarUpload(file){
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 <= 2;

        if (!isJPG) {
          this.$message.error('请上传图片');
        }
        if (!isLt2M) {
          this.$message.error('图片大小请控制在2M以内');
        }
        return isJPG && isLt2M;
			},
			closeExpress(){
				this.sendForm.ExpressId = ''
				this.sendForm.ExpressNo = ''
				this.$refs.sendForm.clearValidate()
			},
			async callagain(){
				try{
					this.detailoading = true
					let res = await inCityDeliverysendCall({
						OrderNo:this.OrderNo,
						InCityDeliveryCompanyType:this.orderDetail.DeliveryInfo.InCityDeliveryCompanyType
					})
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.getOrderdata()
					}
				}finally{
					this.detailoading = false
				}
			},
			async confirmAddtip(){
				let two = /^\d+(\.\d{1,2})?$/
				if(!this.InCityDeliveryGratuityFee){
					this.$message.error('请输入本次小费金额')
					return
				}else if(this.InCityDeliveryGratuityFee<1||this.InCityDeliveryGratuityFee>200||!two.test(this.InCityDeliveryGratuityFee)){
					this.$message.error('小费金额请输入在1~200元之间，最多2位小数')
					return
				}else if(this.tipMsg.AddGratuityTimes>=10){
					this.$message.error('每笔订单最多增加10次小费')
					return
				}else if(this.tipMsg.TotalGratuityFee>=200){
					this.$message.error('每笔订单最多增加200元小费')
					return
				}
				try{
					this.addtipLoading = true
					let res=  await inCityDeliveryaddGratuityFee({
						OrderNo:this.OrderNo,
						InCityDeliveryGratuityFee:this.InCityDeliveryGratuityFee,
					})
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.addtipShow=false
						this.InCityDeliveryGratuityFee=null
						this.getOrderdata()
					}
				}finally{
					this.addtipLoading = false
				}
			},
			async toAddtip(){
				this.addtipShow = true
				try{
					this.addtipLoading = true
					let res = await inCityDeliveryaddGratuityFeeinit({
						OrderNo:this.OrderNo,
					})
					if(res.IsSuccess){
						this.tipMsg = res.Result
					}
				}finally{
					this.addtipLoading = false
				}
			},
			async confirmcancal(){
				if(!this.CancelCode){
					this.$message.error('请选择取消原因')
					return
				}
				try{
					this.cancalclllLoading = true
					let money = await inCityDeliverydeductionFee({
						OrderNo:this.OrderNo,
					})
					if(money.IsSuccess){
						const h = this.$createElement;
						this.$msgbox({
							title: '提示',
							message: h('div', {class:'flexRow'}, [
								h('i',{class:'el-icon-warning',style:'color:#E6A23C;font-size:24px'}),
								h('div',{style:'margin-left:10px'},[
									h('span',null,'本次取消，预计扣除'),
									h('span',{style:'color:#f56c6c'},money.Result.DeductionFeeMoney+'元'),
									h('span',null,'违约金。是否确认继续取消？'),
								])
							]),
							showCancelButton: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
						}).then(async () => {
							try{
								this.cancalclllLoading = true
								let res = await inCityDeliverycancelCall({
									OrderNo:this.OrderNo,
									CancelCode:this.CancelCode,
								})
								if(res.IsSuccess){
									this.$message.success('操作成功')
									this.cancalclllShow = false
									this.CancelCode = null
									this.getOrderdata()
								}
							}finally{
								this.cancalclllLoading = false
							}
						}).catch(() => {});
					}
				}finally{
					this.cancalclllLoading = false
				}
			},
			looklocation(){
				window.open(this.orderDetail.DeliveryInfo.DeliveryTrackLinkUrl)
			},
			expectTime(time){
				if(!time) return
				let newtime = new Date(new Date(time).toLocaleDateString()).getTime()
				let today = new Date(new Date().toLocaleDateString()).getTime()
				let hour = new Date(time).getHours()
				let min = new Date(time).getMinutes()>10?new Date(time).getMinutes():'0'+new Date(time).getMinutes()
				if(newtime==today){
					return `尽快送达 (今日${hour}:${min})`
				}else{
					return time.substring(0,16)
				}
			},
			//复制
	         duplication () {
                 var clipboard = new Clipboard('.tag-read')
                     clipboard.on('success', e => {
                         this.$message({
						      message: '复制成功',
						      type: 'success'
					     });
              // 释放内存
                     clipboard.destroy()
		      })
                     clipboard.on('error', e =>{
              // 释放内存
                     clipboard.destroy()
              })},
			handleModifySend(oldExpressValue, oldExpressNo, DetailIdList) {
				this.sendForm.ExpressId = ''
				this.sendForm.ExpressNo = ''
				this.sendForm.oldExpressValue = oldExpressValue
				this.sendForm.oldExpressNo = oldExpressNo
				this.sendForm.DetailIdList = DetailIdList
				this.getExpressList()
			},
			async getExpressList() {
				this.sendLoading = true
				try {
					let result = await orderIndexinit()
					this.ExpressList = result.Result.ExpressList;
					if (result.IsSuccess) {
						this.modifySendVisible = true
					}
				} catch (e) {
					console.log(e)
					this.sendLoading = false
				} finally {
					this.sendLoading = false
				}
			},
			submitModifySends(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.sendLoading = true;
						try {
							let data = {
								OrderNo: this.orderDetail.OrderNo,
								ExpressNo: this.sendForm.ExpressNo,
								Express: this.sendForm.ExpressId,
								DetailIdList: this.sendForm.DetailIdList
							}
							let result = await orderEditExpress(data)
							if (result.IsSuccess) {
								this.modifySendVisible = false
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
							}
						} catch (error) {
							console.log(error)
							this.sendLoading = false;
						} finally {
							this.sendLoading = false;
							this.getOrderdata()
						}
					} else {
						return false;
					}
				});
			},

			initScreenWidth() {
				let screenWidth = document.body.clientWidth;
				window.onresize = () => {
					return (() => {
						screenWidth = document.body.clientWidth;
					})();
				};
				// console.log(screenWidth)
				this.widthUp1366 = screenWidth > 1366
				this.bages.size = this.widthUp1366 ? 9 : 5
			},
			handleChangeTab(tab, event) {
				let index = tab.index
				if(this.orderDetail.ExpressList&&this.orderDetail.ExpressList.length){
					this.imgsList = this.orderDetail.ExpressList[index].ProductList
				}else if(this.orderDetail.SendType==2){
					this.imgsList = this.orderDetail.DeliveryInfo.ProductList
				}
				this.bages.current = 1
				this.bages.rest = (this.imgsList.length - this.bages.size * this.bages.current)
			},
			handleLeft() {
				this.bages.current -= 1
				this.bages.rest = (this.imgsList.length - this.bages.size * this.bages.current)
			},
			handleRight() {
				this.bages.current += 1
				this.bages.rest = this.imgsList.length - this.bages.size * this.bages.current
			},
			// 进入客服系统
			async goCustmer() {
				const res = await XJChatGetLoginCert()
				if (process.env.NODE_ENV == 'production') {
					window.open('https://kf.dkycn.cn/page/#/home/clientlist?fromMely=1&cert=' + res.LoginCert +
						'&host=' + location.host + '&clientId=' + this.orderDetail.CustomerId)
				} else {
					window.open('http://localhost:8008/#/home/clientlist?fromMely=1&cert=' + res.LoginCert + '&host=' +
						location.host + '&clientId=' + this.orderDetail.CustomerId)
				}
			},

			setClassName({
				row,
				index
			}) {
				// 通过自己的逻辑返回一个class或者空
				let length = 0
				if (row && row.MixList) {
					length = row.MixList.length
				}
				if (!length) {
					return 'unexpand'
				} else {
					return ''
				}
			},
			// 退款
			refundMoneysChange() {
				// refuseMoneys
				// refuseTable
				if (this.refuseTable[0].RefundCount > this.refuseTable[0].RemainCount) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '最多可退' + this.refuseTable[0].RemainCount + '件!'
					});
					this.refuseTable[0].RefundCount = 0
					return
				}
				if (this.refuseTable[0].RemainMoney == 0) {

					if (this.refuseTable[0].IsRefundFreight && this.refuseTable[0].RefundCount > 0) {
						this.refuseMoneys = this.refuseTable[0].RemainMoney + this.refuseTable[0].FreightMoney
					} else {
						this.refuseMoneys = this.refuseTable[0].RemainMoney
					}

				} else {

					if (this.refuseTable[0].RefundCount < this.refuseTable[0].RemainCount) {
						this.refuseMoneys = this.refuseTable[0].RefundCount * this.refuseTable[0].UnitPrice
						if (this.refuseMoneys > this.refuseTable[0].RemainMoney) {
							this.refuseMoneys = this.refuseTable[0].RemainMoney
						}
					}

					if (this.refuseTable[0].RefundCount == this.refuseTable[0].RemainCount) {
						if (this.refuseTable[0].IsRefundFreight) {
							this.refuseMoneys = this.refuseTable[0].RemainMoney + this.refuseTable[0].FreightMoney
						} else {
							this.refuseMoneys = this.refuseTable[0].RemainMoney
						}
					}

				}

				this.refuseMoneys = this.refuseMoneys.toFixed(2)
			},

			// 查看组合商品
			async lookCounpGoods(record) {
				try {
					let result = await detailcomboProduct({
						OrderDetailId: record.Id
					})
					this.contactData = result.Result

				} catch (e) {
					console.log(e)
				} finally {
					this.contactVisivble = true
				}

			},
			//获取地址数据
			getDataList() {
				var that = this
				axios.get('https://cdn.dkycn.cn/JSON/gdDistrict-v2.json?v='+new Date().getTime(), {})
					.then(function(response) {
						that.dataList = response.data
					})
					.catch(function(error) {})
			},
			handleAddressChange() {
				this.ruleForm.ProvinceId = this.ruleForm.AddressArr[0]
				this.ruleForm.CityId = this.ruleForm.AddressArr[1]
				this.ruleForm.AreadId = this.ruleForm.AddressArr[2]
				this.dataList.map(item => {
					if (item.id == this.ruleForm.ProvinceId) {
						this.ruleForm.Province = item.name
						item.child.map(items => {
							if (items.id == this.ruleForm.CityId) {
								this.ruleForm.City = items.name
								items.child.map(lastitems => {
									if (lastitems.id == this.ruleForm.AreadId) {
										this.ruleForm.Area = lastitems.name
									}
								})
							}
						})
					}
				})

				this.center = {
					lng: 0,
					lat: 0
				}

			},

			// 确认订单备注
			sureOrdermark(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true;
						try {
							let data = {
								OrderNo: this.OrderNo,
								SystemRemark: this.orderForm.orderMark,
								SystemImgRemark:this.orderForm.SystemImgRemark,
							}
							let result = await orderRemark(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.ordermarkDialogVisible = false;
								this.getOrderdata()
							}
						} catch (error) {
							console.log(error)
						} finally {
							this.loading = false;
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},


			// 获取订单数据
			async getOrderdata() {
				this.detailoading = true
				try {
					let result = await orderlookDetail({
						OrderNo: this.OrderNo
					})
					this.orderDetail = JSON.parse(JSON.stringify(result.Result));
					// console.log(this.orderDetail)
					this.CustomerRemark = this.orderDetail.CustomerRemark.split('\n')
					// console.log(this.CustomerRemark)
					this.orderDetail.EmployeeAwardMoney = result.Result.EmployeeAwardMoney.toFixed(2)
					this.orderDetail.MemberAwardMoney = result.Result.MemberAwardMoney.toFixed(2)
					this.orderDetail.MemberRecommendAwardMoney = result.Result.MemberRecommendAwardMoney.toFixed(2)
					this.orderDetail.MemberIndirectRecommendAwardMoney = result.Result
						.MemberIndirectRecommendAwardMoney.toFixed(2)
					this.orderDetail.MemberGroupAwardMoney = result.Result.MemberGroupAwardMoney.toFixed(2)

					this.productsData = result.Result.ProductList
					// console.log(this.productsData)
					this.RecordList = result.Result.RecordList
					this.productAll = 0;
					this.discoutAll = 0;
					this.moneyAll = 0;
					this.orderAll = 0;
					this.productsData.map(item => {
						this.productAll = Number(this.productAll) + Number(item.ProductPrice * item
							.ProductCount)
						this.discoutAll = Number(this.discoutAll) + Number(item.ReduceMoney)
						this.moneyAll = Number(this.moneyAll) + Number(item.ProductCashbackMoney)
					})

					this.productAll = this.productAll.toFixed(2)
					this.discoutAll = this.discoutAll.toFixed(2)
					this.moneyAll = this.moneyAll.toFixed(2)
					this.orderAll = (Number(this.productAll) + Number(this.discoutAll) + Number(this.orderDetail
						.FreightMoney)).toFixed(
						2)
					this.ruleForm.Name = result.Result.ReceiveName
					this.ruleForm.phone = result.Result.ReceivePhone
					this.ruleForm.Province = result.Result.ReceiveProvince
					this.ruleForm.City = result.Result.ReceiveCity
					this.ruleForm.Area = result.Result.ReceiveArea
					this.ruleForm.Address = result.Result.ReceiveAddress
					this.ruleForm.AddressArr = [this.orderDetail.ProvinceId + '', result.Result.CityId + '', result
						.Result.AreaId + ''
					]

					this.orderForm = {
						orderMark:result.Result.SystemRemark,
						SystemImgRemark:result.Result.SystemImgRemark,
					}


					this.ChangeFreightMoney = result.Result.ChangeFreightMoney
					if(this.orderDetail.ExpressList&&this.orderDetail.ExpressList.length){
						this.orderDetail.ExpressList.map((t, i) => {
							t.name = 'tab' + (i + 1)
							return t
						})
						this.imgsList = this.orderDetail.ExpressList[0].ProductList
					}
					this.bages.rest = (this.imgsList.length - this.bages.size * this.bages.current)
				} catch (e) {
					console.log(e)
				} finally {
					this.detailoading = false
				}

			},
			// 加星
			addStar() {
				console.log('添加星星')
			},
			// 改变星星
			async rateChange(record) {
				try {
					let data = {
						OrderNo: this.OrderNo,
						Star: this.orderDetail.Star
					}
					let result = await orderIndexStar(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}

				} catch (e) {
					console.log(e)
				} finally {
					// this.getOrderdata()
				}


			},
			// 确认退款
			async sureRefuse() {
				try {
					this.loading = true

					if (!this.refuseTable[0].RefundCount) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请输入退款数量!'
						});
						return
					}

					let data = {
						OrderNo: this.OrderNo,
						IsVirtualRefund: this.isVirtualRefund,
						OrderDetailId: this.OrderDetailId,
						IsCancelAuth: this.ruleForm.resource,
						RefundCount: this.refuseTable[0].RefundCount,
						PayNo: this.orderDetail.PayNo,
						noError:true,
					}

					// console.log(123)
					let result = await ordererefundsave(data)
					// result.ErrorCode = 20000015
					if(result.ErrorCode==20000015){
						this.$confirm('配送员正在送货，为避免商品多发无法追回，该节点暂不支持退款。客户可在配送完成后，在商城内申请售后', '提示', {
							confirmButtonText: '好的',
							cancelButtonText: '取消',
							showCancelButton:false,
							type: 'warning'
						}).then(() => {
							this.returnselfVisivble = false
						}).catch(() => {});
						return 
					}
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.getOrderdata()
						this.returnselfVisivble = false
					}else{
						this.$message.error(result.Message)
					}


				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			// 退款信息
			async returnData() {
				this.loading = true
				try {
					this.refuseTable = []
					let data = {
						OrderNo: this.OrderNo,
						OrderDetailId: this.OrderDetailId,
					}
					let result = await ordererefundInit(data);
					this.show=result.Result.IsShowSpellGroupTip
					this.IsShowSpellGroupTip = result.Result.IsShowSpellGroupTip
					this.refuseTable.push(result.Result)
					this.refuseTable.map(item => {
						item.RefundCount = null
						return item
					})
					//初始化价格
					if (this.refuseTable[0].RefundCount < this.refuseTable[0].RemainCount) {
						this.refuseMoneys = this.refuseTable[0].RefundCount * this.refuseTable[0].UnitPrice
						if (this.refuseMoneys > this.refuseTable[0].RemainMoney) {
							this.refuseMoneys = this.refuseTable[0].RemainMoney
						}
					}


					if (this.refuseTable[0].RefundCount == this.refuseTable[0].RemainCount) {
						if (this.refuseTable[0].IsRefundFreight) {
							this.refuseMoneys = this.refuseTable[0].RemainMoney + this.refuseTable[0].FreightMoney
						} else {
							this.refuseMoneys = this.refuseTable[0].RemainMoney
						}

					}


				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			// 主动退款
			refuseMoeny(record, isVirtualRefund) {
				if(this.orderDetail.SendType==2){
					this.$confirm('该订单客户选择的是同城配送服务，若订单已经开始配送，主动退款前，请告知相关配送人员，避免商品多发。',{
						confirmButtonText: '继续退款',
						cancelButtonText: '关闭',
						center: true
					}).then(() => {
						this.OrderDetailId = record.Id
						if(this.isVirtualRefund = isVirtualRefund){
							this.title="主动退款-虚拟退款"
						}
						this.returnselfVisivble = true
						this.returnData()
						// this.torefuseMoeny(record, isVirtualRefund)
					}).catch(() => {});
				}else{
					this.torefuseMoeny(record, isVirtualRefund)
				}
				
			},
			torefuseMoeny(record, isVirtualRefund){
				this.OrderDetailId = record.Id
				if(this.isVirtualRefund = isVirtualRefund){
					this.title="主动退款-虚拟退款"
				}
				//this.isVirtualRefund = isVirtualRefund
				 if(this.orderDetail.RoutuanDealState==1||this.orderDetail.RoutuanDealState==2){
        	const h = this.$createElement;
					this.$msgbox({
						title: '提示',
						message: h('div', null, [
							h('span', { style: 'color:#f56c6c' }, '肉团团购单'),
							h('span', null, '，主动退款前请与肉团确认是否允许退款 '),
						]),
						confirmButtonText: '与肉团确认无误，继续退款',
						cancelButtonText: '关闭',
						showCancelButton: true,
						type: 'warning'
					}).then(() => {
						this.returnselfVisivble = true
					})
				}else if (this.orderDetail.OrderType == 2 && (this.orderDetail.OrderState == 2 || this.orderDetail.OrderState == 3)) {
					this.nosendVisivble = true
				} else if (this.orderDetail.OrderType == 2 && (this.orderDetail.OrderState == 4 || this.orderDetail
						.OrderState == 5)) {
					this.successVisivble = true
				} else if (this.orderDetail.OrderType != 2 && this.orderDetail.OrderState == 3) {
					this.checkVisivble = true;
				}else {
					this.returnselfVisivble = true
				}
				this.returnData();
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.returnselfVisivble = true;
						this.successVisivble = false;

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//关闭收货地址弹窗
			modelClose() {
				this.editAddressModel = false
				// this.ruleForm = {
				// 	OrderNo: '',
				// 	Name: '',
				// 	phone: '',
				// 	province: '',
				// 	City: '',
				// 	Area: '',
				// 	Address: '',
				// 	AddressArr: []
				// }
			},


			async saveAddressinfo(formName) {

				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							let data = {
								OrderNo: this.OrderNo,
								ReceiveName: this.ruleForm.Name,
								ReceivePhone: this.ruleForm.phone,
								ReceiveProvince: this.ruleForm.Province,
								ReceiveCity: this.ruleForm.City,
								ReceiveArea: this.ruleForm.Area,
								ReceiveAddress: this.ruleForm.Address
							}
							let result = await ordereditReceive(data)
							if (result.IsSuccess) {
								this.getOrderdata()
								this.modelClose()
							}
						} catch (error) {

						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});


			},


			// 修改收货地址
			editAdress() {
				if(this.orderDetail.SendType==1||this.orderDetail.SendType==2){
					this.$confirm('仅快递发货订单可修改收货信息',{
						cancelButtonText: '关闭',
						showConfirmButton:false,
						center: true
					})
					return 
				}
				if (this.orderDetail.SupplierMallId) {
					this.$message({
						showClose: true,
						type: 'infor',
						message: '分销采购单不支持修改收货信息!'
					});
					return
				}


				if (this.orderDetail.SendType == 0) {
					this.editAddressModel = true
				} else {
					this.noeditesVisivble = true;
				}

			},

			// 查看优惠明细
			async lookBelifDetails() {
				try {

					let result = await detailcomboDetail({
						OrderNo: this.OrderNo
					})
					this.ordermenyData = result.Result
					this.ordermenyData.map(t => {
						t.ReduceMoney = t.ReduceMoney.toFixed(2)
						return t
					})

				} catch (e) {
					console.log(e)
				} finally {
					this.orderdetailsVisivble = true;
				}
			},
		}
	}
</script>

<style lang="less">
.popperClass{
	max-width: 400px;
}
</style>

<style lang="less" scoped>
.img-remark{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.remark-re{
			position: relative;
			top: 0;
			left: 0;
			width:60px;height:60px;
			border-radius:3px;
			// border:1px solid black;
			margin: 0px 10px 10px 0px;
			.remark-close{
				width:14px;height:14px;
				border-radius: 50%;
				position: absolute;
				top: -7px;
				left: calc(100% - 7px);
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				z-index: 500;
			}
		}
		.upload-box{
			::v-deep .el-upload--picture-card{
				width: 60px;height:60px;
				line-height:60px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
.el-image-whiteclose{
	::v-deep .el-icon-circle-close{
		color:white
	}
}
.system-remark {
	background: rgba(253, 246, 236, 1);
	color: rgba(253, 162, 60, 1);
	padding: 10px;
	word-wrap: break-word;
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	font-size: 14px;
}
.flexRow{
	display: flex;
	align-items: center;
}
.remind_l{
	background: #FEF0F0;
	font-size: 12px;
	color: #F56C6C;
	margin-bottom:10px;
	display:flex;
	align-items: center;
}
.remind{
	width: 818px;
	height: 36px;
	font-size: 12px;
	font-weight: 400;
	background: #FDF6EC;
    color: #E6A23C;
    line-height: 36px;
	margin-bottom: 10px;
}
.orderDetailpage {
		width: 100%;
		padding: 10px;

		.orderInformation {
			width: 100%;

			.mainCon {
				width: 100%;
				margin-bottom: 15px;
				box-sizing: border-box;
				padding: 20px 60px 10px 60px;
				background: #fff;

				.pack-tabs {
					display: flex;
					flex-direction: row;

					.left-content {
						width: 50%;
						font-size: 14px;
						line-height: 28px;
						padding-top: 10px;

						.imgs-content {
							margin-top: 20px;
							margin-left: 10px;
							width: 425px;
							position: relative;
							display: flex;
							flex-direction: row;

							.imgs-list {
								width: 375px;
								margin-left: 25px;
								overflow: hidden;

								ul,
								li {
									padding: 0;
									margin: 0;
									list-style: none
								}

								ul {
									display: flex;
									flex-direction: row;
									transform: translateX(0px);
									transition: all 1s ease;
								}

								.imgs-item {
									margin: 0 5px;

									img {
										cursor: pointer;
										border: 1px solid #ddd;
										border-radius: 5px;
										width: 65px;
										height: 65px;
										object-fit: contain;
									}

									div {
										width: 65px;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
										text-align: center;
									}
								}
							}

							.icon-left {
								cursor: pointer;
								position: absolute;
								left: -20px;
								font-size: 45px;
								line-height: 65px;
								color: #909399;
							}

							.icon-unleft {
								position: absolute;
								left: -20px;
								font-size: 45px;
								line-height: 65px;
								color: #ddd;
							}

							.icon-right {
								cursor: pointer;
								position: absolute;
								right: -20px;
								font-size: 45px;
								line-height: 65px;
								color: #909399;
							}

							.icon-unright {
								position: absolute;
								right: -20px;
								font-size: 45px;
								line-height: 65px;
								color: #ddd;
							}
						}
					}

					.right-content {
						width: 50%;
					}
				}

				.info-con {
					margin-bottom: 20px;
					font-size: 14px;
					display: flex;
					line-height: 28px;
					flex-direction: row;
					justify-content: space-between;

					.shipping-address2 {
						width: 200px;
						overflow: hidden;
						// word-wrap: break-word;
						display: -webkit-box;
						-webkit-line-clamp: 2; //只展示5行
						-webkit-box-orient: vertical;
					}
				}

				.line {
					margin-bottom: 20px;
					font-size: 14px;

					.shipping-address {
						width: 95%;
						overflow: hidden;
						word-wrap: break-word;
						display: -webkit-box;
						-webkit-line-clamp: 7; //只展示5行
						-webkit-box-orient: vertical;
					}

					span {
						line-height: 28px;
					}

					div {
						line-height: 28px;
					}
				}

			}

		}

		.el-alert {
			border-radius: 0;
		}

		.comeback-money {
			// padding-right: 50px;
			width: 100%;
			text-align: right;
			font-size: 14px;

			.allMoney {
				border-top: 1px solid #ddd;
				padding-top: 20px;
			}
		}

		.self-refuse {
			display: inline-block;
			width: 75px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			font-size: 14px;
			color: #F56C6C;
			border: 1px solid #F56C6C;
			cursor: pointer;
			margin-top: 5px;
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.sendOrders {
			line-height: 23px;
		}

	}

	.refund-record {
		background-color: #fff;
		margin-top: 10px;
		padding: 20px;
		overflow: hidden;

		.title {
			font-weight: bold;
		}

		.refund-timeline {
			margin-top: 20px;

			::v-deep .el-timeline {
				padding-left: 10px !important;
			}
		}
	}

	.el-alert {
		.el-alert__content {
			width: 100%;
		}

		.el-alert__title {
			word-wrap: break-word;
		}
	}

	.reply-expand {
		::v-deep .el-table th {
			padding: 0 !important;
		}

		// z-index: 999;
		width: 100%;
		background-color: #f4f5f7;
		// margin-top:-1px;
		display: flex;
		flex-direction: row;
		padding: 10px;

		.product-info {
			width: 390px;
			// background-color: #409EFF;
		}

		.product-msg {
			word-wrap: break-word;
			width: 120px;
			padding: 0 9px;
			display: flex;
			align-items: center;
		}

		.product-spec {

			word-wrap: break-word;
			width: 120px;
			padding: 0 9px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

	}

	.old-send-mess {
		line-height: 30px;
		border: 1px solid #E6A23C;
		background-color: #FDF6EC;
		color: #E6A23C;
		padding: 10px;
		margin-bottom: 20px;
	}

	::v-deep .el-dialog__body {
		padding-top: 10px;
	}

	::v-deep .unexpand .el-table__expand-column .cell {
		display: none;
	}

	::v-deep .unexpand .el-table__expanded-cell {
		display: none;
	}

	::v-deep .el-table__expanded-cell[class*=cell] {
		padding: 0 0 0 50px;
	}

	::v-deep .el-table__expanded-cell {
		padding-left: 50px;
	}

	// ::v-deep .hiddenCheck .el-checkbox__input {
	// 	visibility: hidden;
	// }
</style>
